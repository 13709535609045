// String
export const API_DOMAIN = toString(import.meta.env.VITE_API_DOMAIN)
export const APP_VERSION = toString(import.meta.env.VITE_APP_VERSION)
export const GA_TRACKING_ID = toString(import.meta.env.VITE_GA_TRACKING_ID)
export const OPT_CONTAINER_ID = toString(import.meta.env.VITE_OPT_CONTAINER_ID)
export const RECAPTCA_SITEKEY = toString(import.meta.env.VITE_RECAPTCA_SITEKEY)
export const DETECKER_PLUS_SCRIPT = toString(import.meta.env.VITE_DETECKER_PLUS_SCRIPT)
export const STREAM_DETECKER_SCRIPT = toString(import.meta.env.VITE_STREAM_DETECKER_SCRIPT)
export const FROM_MAIL_ADDRESS = toString(import.meta.env.VITE_FROM_MAIL_ADDRESS)
export const DEEP_LINK_URL = toString(import.meta.env.VITE_DEEP_LINK_URL)
export const JPKI_APP_STORE_URL_ANDROID = toString(import.meta.env.VITE_JPKI_APP_STORE_URL_ANDROID)
export const JPKI_APP_STORE_URL_IOS = toString(import.meta.env.VITE_JPKI_APP_STORE_URL_IOS)

// Boolean
export const ACCESS_ALLOW_ONLY_SP = toBoolean(import.meta.env.VITE_ACCESS_ALLOW_ONLY_SP)
export const USE_MOCK_PHOTO = toBoolean(import.meta.env.VITE_USE_MOCK_PHOTO)
export const USE_STRICT_MODE = toBoolean(import.meta.env.VITE_USE_STRICT_MODE)

/**
 * Booleanにキャストした値を返却する
 *
 * @param value
 */
function toBoolean (value: string | undefined): boolean {
  /*
   * undefined, '', '0', 'false' -> false
   * それ以外 -> true
   */
  return value === '0' || value === 'false'
    ? false
    : Boolean(value)
}

/**
 * 未設定の場合は空文字として返却する
 *
 * @param value
 */
function toString(value: string | undefined): string {
  return typeof value === 'undefined'
    ? ''
    : value;
}
