interface ILiveActionTypes {
  key: number;
  value: string;
  example: string;
  recording?: string;
}

interface IDocTypes {
  key: string;
  value: string;
  hint: string;
  expirationLabelKey: string;
  numberLabelKey?: string;
  regex?: RegExp;
  photo: IPhoto;
}

interface IPhoto {
  selfie: IPhotoSelfie;
  selfieBack?: IPhotoSelfieBack;
  front: IPhotoFront;
  slope: IPhotoSlope;
  back?: IPhotoBack;
  marked: IPhotoMaked;
}

interface IPhotoSelfie {
  explanationImage: string;
  shootingImage: string;
  rect: object;
}

interface IPhotoSelfieBack {
  explanationImage: string;
  lines?: object;
  rect?: object;
}

interface IPhotoFront {
  explanationImage: string;
  shootingImage: string;
  rect: object;
}

interface IPhotoSlope {
  explanationImage: string;
  shootingImage: string;
}

interface IPhotoBack {
  explanationImage: string;
  rect: object;
}

interface IPhotoMaked {
  image: string;
}

interface IMemberStatus {
  AUTHENTICATED: number;
  AWAITING_REVIEW: number;
  P_REVIEWING: number;
  P_PENDING: number;
  P_OK: number;
  P_NG: number;
  REVIEWING: number;
  PENDING: number;
  MEMBER: number;
  SUSPEND: number;
  REJECTED: number;
  DELETED: number;
  P_DELETED: number;
  RE_REVIEWING: number;
  MODIFYING: number;
}

interface IAccountType {
  SPEED: number;
  TRUST: number;
  TRUST4FINANCE: number;
}

interface IDocumentType {
  DRIVER_LICENCE: number;
  MY_NUMBER_CARD: number;
  ZAIRYU_CARD: number;
}

interface IAuthenticationType {
  FACE: number;
  SMS_FACE: number;
  MAIL_SMS_FACE: number;
}

interface IErrorCode {
  SYSTEM_ERROR: string;
  SCREEN_TRANSITION_INVALID: string;
  DEVICE_ERROR: string;
  ACCOUNT_LOCK: string;
  CODE_SEND_LIMIT: string;
  ATM_ACCOUNT_LOCKED: string;
  TOKEN_ERROR: string;
  AUTHENTICATION_EXPIRED: string;
  CODE_INVALID: string;
  QR_TERM_EXPIRED: string;
  QR_CODE_INVALID: string;
  FACE_UPDATE_INVALID: string;
  EMAIL_AUTHORIZATION_ERROR: string;
  EMAIL_AUTHORIZATION_LIMIT: string;
  UNSUBSCRIBE_ERROR1: string;
  UNSUBSCRIBE_ERROR2: string;
  FACE_AUTH_OTP_INVALID: string;
  FACE_MATCH_INVALID: string;
  DIGITAL_CERTIFICATE_REVOKED: string;
  DIGITAL_CERTIFICATE_EXPIRED: string;
  JPKI_APP_PROCESSING_RESULT_FAILED: string;
  JPKI_APP_PROCESSING_RESULT_CANCELED: string;
  OTHER: string;
}

interface IInputType {
  TEL: number;
  TEXT: number;
  BIRTH_DAY: number;
  CHECKBOX: number;
}

import liveExampleLeftImage from '@/assets/img/photo/picture-example-left.png';
import liveRecordingLeftImage from '@/assets/img/photo/recording-left.png';
import liveExampleRightImage from '@/assets/img/photo/picture-example-right.png';
import liveRecordingRightImage from '@/assets/img/photo/recording-right.png';

import selfieDriversExplanationImage from '@/assets/img/photo/wire-selfie-card-drivers-explanation.png';
import selfieDriversShootingImage from '@/assets/img/photo/wire-selfie-card-drivers-shooting.png';
import selfieBackDriversExplanationImage from '@/assets/img/photo/wire-selfieBack-card-drivers.png';
import frontDriversExplanationImage from '@/assets/img/photo/wire-front-drivers-explanation.png';
import frontDriversShootingImage from '@/assets/img/photo/wire-front-drivers-shooting.png';
import slopeDriversExplanationImage from '@/assets/img/photo/wire-slope-card-drivers.png';
import slopeDriversShootingImage from '@/assets/img/photo/wire-slope-card-thickness-drivers.png';
import backDriversExplanationImage from '@/assets/img/photo/wire-back-drivers.png';
import frontDriversMarkedImage from '@/assets/img/photo/wire-front-drivers-marked.png';

import selfieMynumberExplanationImage from '@/assets/img/photo/wire-selfie-card-mynumber-explanation.png';
import selfieMynumberShootingImage from '@/assets/img/photo/wire-selfie-card-mynumber-shooting.png';
import frontMynumberExplanationImage from '@/assets/img/photo/wire-front-mynumber-explanation.png';
import frontMynumberShootingImage from '@/assets/img/photo/wire-front-mynumber-shooting.png';
import slopeMynumberExplanationImage from '@/assets/img/photo/wire-slope-card-mynumber.png';
import slopeMynumberShootingImage from '@/assets/img/photo/wire-slope-card-thickness-mynumber.png';
import frontMynumberMarkedImage from '@/assets/img/photo/wire-front-mynumber-marked.png';

import selfieZairyuExplanationImage from '@/assets/img/photo/wire-selfie-card-zairyu-explanation.png';
import selfieZairyuShootingImage from '@/assets/img/photo/wire-selfie-card-zairyu-shooting.png';
import selfieBackZairyuExplanationImage from '@/assets/img/photo/wire-selfieBack-card-zairyu.png';
import frontZairyuExplanationImage from '@/assets/img/photo/wire-front-zairyu-explanation.png';
import frontZairyuShootingImage from '@/assets/img/photo/wire-front-zairyu-shooting.png';
import slopeZairyuExplanationImage from '@/assets/img/photo/wire-slope-card-zairyu.png';
import slopeZairyuShootingImage from '@/assets/img/photo/wire-slope-card-thickness-zairyu.png';
import backZairyuExplanationImage from '@/assets/img/photo/wire-back-zairyu.png';
import frontZairyuMarkedImage from '@/assets/img/photo/wire-front-zairyu-marked.png';

const config: {
  liveActionTypes: ILiveActionTypes[],
  docTypes: IDocTypes[],
  MEMBER_STATUS: IMemberStatus,
  ACCOUNT_TYPE: IAccountType,
  DOCUMENT_TYPE: IDocumentType,
  BIRTH_YEAR_MAX_DISPLAY_NUMBER: number,
  AUTHENTICATION_TYPE: IAuthenticationType,
  ERROR_CODE: IErrorCode,
  STREAMING_RECEPTION_COMPANY_ID: string,
  INPUT_TYPE: IInputType,
} = {
  /**
   * LiveDetection のアクション種別
   *
   * @member {array}
   */
  liveActionTypes: [
    { key: 0, value: 'live_action.front', example: '' },
    {
      key: 1,
      value: 'live_action.left',
      example: liveExampleLeftImage,
      recording: liveRecordingLeftImage
    },
    {
      key: 2,
      value: 'live_action.right',
      example: liveExampleRightImage,
      recording: liveRecordingRightImage
    }
  ],

  /**
   * 身分証明書種別リスト
   *
   * @member {array}
   */
  docTypes: [
    {
      key: '1',
      value: 'doc_type.drivers_license',
      hint: 'hint_about_expiration_date.drivers_license',
      expirationLabelKey: 'register_attr.list_items.drivers_license_expiration',
      numberLabelKey: 'doc_type_number.drivers_license_number',
      regex: /^[0-9]{12}$/,
      photo: {
        selfie: {
          explanationImage: selfieDriversExplanationImage,
          shootingImage: selfieDriversShootingImage,
          // 身分証明書、表面写真の枠座標
          rect: {
            x: 77,
            y: 128,
            width: 71,
            height: 92,
            radius: 5
          }
        },
        selfieBack: {
          explanationImage: selfieBackDriversExplanationImage,
          // 身分証明書、裏面写真の枠座標
          rect: {
            x: 0,
            y: 95,
            width: 225,
            height: 64,
            radius: 0
          }
        },
        front: {
          explanationImage: frontDriversExplanationImage,
          shootingImage: frontDriversShootingImage,
          // 身分証明書、表面写真の枠座標
          rect: {
            x: 88,
            y: 12,
            width: 80,
            height: 104,
            radius: 5
          }
        },
        slope: {
          explanationImage: slopeDriversExplanationImage,
          shootingImage: slopeDriversShootingImage
        },
        back: {
          explanationImage: backDriversExplanationImage,
          // 身分証明書、裏面内部の枠座標
          rect: {
            x: 0,
            y: -36,
            width: 255,
            height: 74
          }
        },
        marked: {
          image: frontDriversMarkedImage
        }
      }
    },
    {
      key: '2',
      value: 'doc_type.my_number_card',
      hint: 'hint_about_expiration_date.my_number_card',
      expirationLabelKey: 'register_attr.list_items.my_number_card_expiration',
      photo: {
        selfie: {
          explanationImage: selfieMynumberExplanationImage,
          shootingImage: selfieMynumberShootingImage,
          // 身分証明書、表面写真の枠座標
          rect: {
            x: -79.5,
            y: 139,
            width: 66,
            height: 81,
            radius: 5
          }
        },
        front: {
          explanationImage: frontMynumberExplanationImage,
          shootingImage: frontMynumberShootingImage,
          // 身分証明書、表面写真の枠座標
          rect: {
            x: -90.5,
            y: 15.5,
            width: 74,
            height: 92,
            radius: 5
          }
        },
        slope: {
          explanationImage: slopeMynumberExplanationImage,
          shootingImage: slopeMynumberShootingImage
        },
        marked: {
          image: frontMynumberMarkedImage
        }
      }
    },
    {
      key: '3',
      value: 'doc_type.zairyu_card',
      hint: 'hint_about_expiration_date.zairyu_card',
      expirationLabelKey: 'register_attr.list_items.zairyu_card_expiration',
      numberLabelKey: 'doc_type_number.zairyu_card_number',
      regex: /^[A-Z]{2}[0-9]{8}[A-Z]{2}$/,
      photo: {
        selfie: {
          explanationImage: selfieZairyuExplanationImage,
          shootingImage: selfieZairyuShootingImage,
          // 身分証明書、表面写真の枠座標
          rect: {
            x: 79.5,
            y: 137,
            width: 66,
            height: 86,
            radius: 5
          }
        },
        selfieBack: {
          explanationImage: selfieBackZairyuExplanationImage,
          // 在留カードの背面線画
          lines: [
            {
              x1: -112.5,
              y1: 155,
              x2: 112.5,
              y2: 155
            },
            {
              x1: 60,
              y1: 155,
              x2: 60,
              y2: 195
            }
          ]
        },
        front: {
          explanationImage: frontZairyuExplanationImage,
          shootingImage: frontZairyuShootingImage,
          // 身分証明書、表面写真の枠座標
          rect: {
            x: 90,
            y: 12,
            width: 75,
            height: 98,
            radius: 5
          }
        },
        slope: {
          explanationImage: slopeZairyuExplanationImage,
          shootingImage: slopeZairyuShootingImage,
        },
        back: {
          explanationImage: backZairyuExplanationImage,
          // 身分証明書、裏面内部の枠座標
          rect: {
            x: -37.5,
            y: 62.5,
            width: 180,
            height: 22
          }
        },
        marked: {
          image: frontZairyuMarkedImage
        }
      }
    }
  ],

  /**
   * 会員ステータス
   *
   * @type {IMemberStatus}
   */
  MEMBER_STATUS: {
    AUTHENTICATED: 0,
    AWAITING_REVIEW: 1,
    P_REVIEWING: 2,
    P_PENDING: 3,
    P_OK: 4,
    P_NG: 5,
    REVIEWING: 6,
    PENDING: 7,
    MEMBER: 8,
    SUSPEND: 9,
    REJECTED: 10,
    DELETED: 11,
    P_DELETED: 12,
    RE_REVIEWING: 13,
    MODIFYING: 14
  },

  /**
   * 本人確認レベル
   *
   * @type {IAccountType}
   */
  ACCOUNT_TYPE: {
    SPEED: 1,
    TRUST: 2,
    TRUST4FINANCE: 3
  },

  /**
   * 本人確認書類
   *
   * @type {IDocumentType}
   */
  DOCUMENT_TYPE: {
   DRIVER_LICENCE: 1,
   MY_NUMBER_CARD: 2,
   ZAIRYU_CARD: 3
 },

  /**
   * 誕生年セレクトの最大表示数
   * @type {number}
   */
  BIRTH_YEAR_MAX_DISPLAY_NUMBER: 100,

  /**
   * (顔認証用)認証タイプ
   *
   * @type {IAuthenticationType}
   */
  AUTHENTICATION_TYPE: {
    FACE: 1,
    SMS_FACE: 2,
    MAIL_SMS_FACE: 3
  },

  /**
   * 画面エラーコード
   *
   * @type {IErrorCode}
   */
  ERROR_CODE: {
    SYSTEM_ERROR: '1001',
    SCREEN_TRANSITION_INVALID: '1011',
    DEVICE_ERROR: '1021',
    ACCOUNT_LOCK: '2001',
    CODE_SEND_LIMIT: '2002',
    ATM_ACCOUNT_LOCKED: '2003',
    TOKEN_ERROR: '2011',
    AUTHENTICATION_EXPIRED: '2021',
    CODE_INVALID: '2031',
    QR_TERM_EXPIRED: '2041',
    QR_CODE_INVALID: '2042',
    FACE_UPDATE_INVALID: '2051',
    EMAIL_AUTHORIZATION_ERROR: '2061',
    EMAIL_AUTHORIZATION_LIMIT: '2062',
    UNSUBSCRIBE_ERROR1: '3001',
    UNSUBSCRIBE_ERROR2: '3002',
    FACE_AUTH_OTP_INVALID: '4001',
    FACE_MATCH_INVALID: '4002',
    DIGITAL_CERTIFICATE_REVOKED: '5001',
    DIGITAL_CERTIFICATE_EXPIRED: '5002',
    JPKI_APP_PROCESSING_RESULT_FAILED: '5011',
    JPKI_APP_PROCESSING_RESULT_CANCELED: '5012',
    OTHER: '9999'
  },

  /**
   * STREAMING_RECEPTIONに送るCOMPANY_ID
   * @type {string}
   */
   STREAMING_RECEPTION_COMPANY_ID: 'PRST01',

   /**
    * 個社項目の input type
    *
    * @type {IInputType}
    */
   INPUT_TYPE: {
     TEL: 1,
     TEXT: 2,
     BIRTH_DAY: 3,
     CHECKBOX: 4
   }
};

export default config;

/**
 * メールアドレス登録状況
 *
 * @type {object}
 */
export const EmailRegisterStatus = {
  NOT_REGISTERED: 0,
  NOT_COMMUNICATED: 1,
  COMMUNICATED: 2
};
