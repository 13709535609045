import { configure, defineRule } from 'vee-validate';
import { alpha, digits, email, max, min, required, numeric } from '@vee-validate/rules';
import { localize } from '@vee-validate/i18n';
import en from '@vee-validate/i18n/dist/locale/en.json';
import es from '@vee-validate/i18n/dist/locale/es.json';
import id from '@vee-validate/i18n/dist/locale/id.json';
import ja from '@vee-validate/i18n/dist/locale/ja.json';
import my from '@vee-validate/i18n/dist/locale/en.json';
import pt from '@vee-validate/i18n/dist/locale/pt_PT.json';
import th from '@vee-validate/i18n/dist/locale/th.json';
import tl from '@vee-validate/i18n/dist/locale/en.json';
import vi from '@vee-validate/i18n/dist/locale/vi.json';
import zh from '@vee-validate/i18n/dist/locale/zh_CN.json';
import enMessages from '@/locales/en/validation.json';
import esMessages from '@/locales/es/validation.json';
import idMessages from '@/locales/id/validation.json';
import jaMessages from '@/locales/ja/validation.json';
import myMessages from '@/locales/my/validation.json';
import ptMessages from '@/locales/pt/validation.json';
import thMessages from '@/locales/th/validation.json';
import tlMessages from '@/locales/tl/validation.json';
import viMessages from '@/locales/vi/validation.json';
import zhMessages from '@/locales/zh/validation.json';
import dayjs from 'dayjs';
import emojiRegex from 'emoji-regex';
import { store } from '@/store';
import config from '@/config';

defineRule('alpha', alpha);
defineRule('digits', digits);
defineRule('email', email);

// 必須
defineRule('required', required);

// 最大文字数
defineRule('max', max);

// 最小文字数
defineRule('min', min);

// 電話番号
defineRule('tel', (value: string): boolean => {
  return /^0[3,6-9]0\s?[1-9]\d{3}\s?\d{4}$/.test(value)
});

// 数値
defineRule('numeric', numeric);

// カナ
export const kanaRule = /^[ァ-ヶー\s]+$/;
export const kana = (value: string): boolean => kanaRule.test(value)
defineRule('kana', kana);

// 半角・全角スペース禁止
defineRule('space', (value: string): boolean => {
  return !/( |　)/.test(value); // eslint-disable-line no-irregular-whitespace
});

// 絵文字禁止ルール
const emoji_prohibit = (value: string): boolean => {
  const reg = emojiRegex();
  // 絵文字のコード範囲指定
  return !reg.test(value);
};

// 絵文字禁止ルール適用
defineRule('emoji_prohibit', emoji_prohibit);

// 身分証明書有効期限の過去制限
defineRule('doc_expire_past_date', (value: any, params: any): boolean => {
  const y: number = parseInt(params.year);
  const m: number = parseInt(params.month);
  const d: number = parseInt(params.day);

  if (!y || !m || !d) {
    return true;
  }

  const input: dayjs.Dayjs = dayjs(`${y}-${m}-${d}`);
  const now: dayjs.Dayjs = dayjs(dayjs().format('YYYY-MM-DD'));
  return input.isSame(now) || input.isAfter(now);
});

// 誕生日の未来日制限
defineRule('birthday_deficiency', (value: any, params: any): boolean => {
  const y: number = parseInt(params.year);
  const m: number = parseInt(params.month);
  const d: number = parseInt(params.day);

  if (!y || !m || !d) {
    return true;
  }

  const input: dayjs.Dayjs = dayjs(`${y}-${m}-${d}`);
  const now: dayjs.Dayjs = dayjs(dayjs().format('YYYY-MM-DD'));
  return input.isSame(now) || input.isBefore(now);
});

// 身分証明書番号
defineRule('doc_number', (value: string): boolean => {
  const doc_type: string = store.getters['DocumentType/document_type'];
  const doc_type_object = config.docTypes.find(({ key }) => key === doc_type);
  if (doc_type_object && doc_type_object.regex) {
    // 身分証明書の種類に合わせて正規表現でチェック
    return doc_type_object.regex.test(value);
  }
  return true;
});

// 個社項目
defineRule('customizes', (value: string, arg: any): boolean => {
  return new RegExp(arg).test(value);
});

// 整合性エラー
defineRule('asTrue', (value: string, arg: any) => {
  return arg == 'true';
});

// シングルバイト文字のみ
defineRule('singleByte', (value: string): boolean => {
  return !!value.match(/^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/)
});

const customLocalize = localize({
  en: {
    messages: {
      ...en.messages,
      ...enMessages
    }
  },
  es: {
    messages: {
      ...es.messages,
      ...esMessages
    }
  },
  id: {
    messages: {
      ...id.messages,
      ...idMessages
    }
  },
  ja: {
    messages: {
      ...ja.messages,
      ...jaMessages
    }
  },
  my: {
    messages: {
      ...my.messages,
      ...myMessages
    }
  },
  pt: {
    messages: {
      ...pt.messages,
      ...ptMessages
    }
  },
  th: {
    messages: {
      ...th.messages,
      ...thMessages
    }
  },
  tl: {
    messages: {
      ...tl.messages,
      ...tlMessages
    }
  },
  vi: {
    messages: {
      ...vi.messages,
      ...viMessages
    }
  },
  zh: {
    messages: {
      ...zh.messages,
      ...zhMessages
    }
  },
});

configure({
  generateMessage: customLocalize,
  validateOnInput: true,
});
